import React from 'react';
import { RatingCard } from './RatingCard';
import * as S from './styles';
import { DashboardCard } from './DashboardCard';
import Link from 'next/link';
import { Text } from '@vette/ui-components';

type Props = {
  loading: boolean;
  allRatingCount?: number | null;
  overallRating?: number | null;
  lastFiveRating?: number | null;
};
export const FeedbackScoreCard: React.FC<Props> = ({
  allRatingCount,
  lastFiveRating,
  overallRating,
  loading,
}) => {
  return (
    <DashboardCard
      loading={loading}
      label="Applicant feedback score"
      src=""
      height="auto"
      minHeight="325"
      tooltip="Rating is provided by Applicant at the end of the interview process."
      hasTooltipImage={true}
    >
      <S.Feedback>
        {allRatingCount === 0 ? (
          <S.NoRatingText>
            <S.RatingCount>
              Your score will appear here after you complete some Vettes
            </S.RatingCount>
          </S.NoRatingText>
        ) : (
          <>
            <RatingCard
              rating={overallRating}
              title="All Time"
              ratingCount={allRatingCount}
            />
            {lastFiveRating && (
              <>
                <S.Divider />

                <RatingCard
                  title="Recent"
                  rating={lastFiveRating}
                  isLastFive={true}
                />
              </>
            )}
          </>
        )}
      </S.Feedback>
      <Link href="https://info.vette.io/vetter-success" target="_blank">
        <Text $size={12} $weight={600} underline>
          Learn how to improve your Vette score
          <S.Launch />
        </Text>
      </Link>
    </DashboardCard>
  );
};
