import React from 'react';
import { DashboardLayout } from '@vette/ui-components';
import { firebase } from '@vette/frontend-utils';
import { vetter } from '@vette/pages';

const Dashboard = () => {
  return (
    <>
      <firebase.FirebaseClaims
        required={{ id: true }}
        fallback={<DashboardLayout loading />}
      >
        {({ id }) => (
          <DashboardLayout hasStatusToggle overflowY="auto">
            <vetter.Dashboard userId={id} />
          </DashboardLayout>
        )}
      </firebase.FirebaseClaims>
    </>
  );
};

export default Dashboard;
