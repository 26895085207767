import React from 'react';
import { Text } from '@vette/ui-components';
import * as S from './styles';

type Props = {
  title: string;
  rating?: number | null;
  ratingCount?: number | null;
  isLastFive?: boolean;
};
export const RatingCard: React.FC<Props> = ({
  title,
  ratingCount,
  rating,
  isLastFive,
}) => {
  return (
    <S.RatingCard direction="vertical">
      <Text $size={15} $weight={600}>
        {title}
      </Text>
      <Text $size={44} $weight={500}>
        {Number(rating?.toFixed(1))}
      </Text>
      <S.Rate disabled allowHalf value={rating ?? 0} />
      <S.RatingCount>
        {isLastFive ? '5 recent' : ratingCount} ratings
      </S.RatingCount>
    </S.RatingCard>
  );
};
